$(function () {
    $(".file-uploader").each(function () {
        mountFileUploader(this);
    });

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
                mutation.addedNodes.forEach(addedNode => {
                  let parentNode = addedNode.parentNode;
                  while (parentNode) {
                    if (parentNode.id && parentNode.id.includes('editable-fields')) {
                        mountFileUploader(mutation.addedNodes[0].parentNode);
                      break;
                    }
                    parentNode = parentNode.parentNode;
                  }
                });
                
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // For accessibility, "enter" keydown events should trigger the file uploader
    if ($('.fileinput-button') && $('.fileinput-button')[0]) {
        $('.fileinput-button')[0].addEventListener('keydown', function (e) {
            if (e.key.toLowerCase() === "enter") {
                $('.fileinput-button')[0].children[2].click();
            }
        });
    }
});

function mountFileUploader(rootUploaderEl) {
    $(rootUploaderEl).find("input[type=file]").each(initFileUpload);
    $(rootUploaderEl).on("click", ".remove-file", removeFile);
    $(rootUploaderEl).on("click", ".remove-custom-file", removeCustomFile);
    $(rootUploaderEl).on("click", ".select-from-files", selectFile);
    $(rootUploaderEl).on("click", ".edit-crop", editCrop);
    $(rootUploaderEl).on("click", ".save-crop", saveCrop);
}

function resetFileUploader(rootUploaderEl, filePath) {
  if (rootUploaderEl.find('.file-uploader-img').attr('src') != baseFileUrl + filePath) {
    FileAdded(filePath, rootUploaderEl, filePath);
    rootUploaderEl.find('[name$="_Original"]').val(filePath)
  }
}

function initFileUpload(index, element) {
    $(this).fileupload({
        url: uploadFileUrl,
        dataType: 'json',
        maxFileSize: 20000000,
        formData: {
            '__RequestVerificationToken': $("[name='__RequestVerificationToken']").val()
        },
        dropZone: $(this).parents(".file-uploader"),
        processalways: function (e, data) {
            var index = data.index,
                file = data.files[index];
            if (file.error) {
                ShowErrorMessage($(this), file.error);
            }
        },
        submit: function (e, data) {
            if (!data.formData)
                data.formData = {};

            data.formData.TargetFolder = $(this).parents(".file-uploader").data("target-folder");
            data.formData.__RequestVerificationToken = $("[name='__RequestVerificationToken']").val();
            data.formData.ReturnBase64 = $(element).data("return-base64");
            data.formData.ContainerType = $(element).data("container-type");
        },
        send: function (e, data) {
            var parentContainer = $(this).parents(".file-uploader");
            parentContainer.find(".progress-bar").css('width', 0);
            parentContainer.find(".progress").show();
            parentContainer.find(".error-message").remove();
        },
        done: function (e, data) {
            if (data.result.error) {
                ShowErrorMessage($(this), data.result.error);
                return;
            }
            var file = data.result;
            var parentContainer = $(this).parents(".file-uploader");

            var customFieldID = $(element).data("custom-field-id");
            var isParentCustomer = $(element).data("is-parent-customer") == "True";
            FileAdded(file.name, parentContainer, file.fullPath, file.type, file.size, customFieldID, isParentCustomer);
            $(this).trigger("file-upload-done", [file]);
        },
        progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $(this).parents(".file-uploader").find(".progress-bar").css('width', progress + '%');
        }
    });
}

function removeFile(e) {
    var parentContainer = $(e.target).parents(".file-uploader");
    var hiddenFileNameInput = parentContainer.find("[type=hidden]:first");
    updateFileName(hiddenFileNameInput, "");

    var croppieContainerElement = parentContainer.find(".file-uploader-img-container");
    var imgPreviewElement = parentContainer.find("img");
    var croppieElement = parentContainer.find(".file-uploader-croppie");

    var editCropButton = parentContainer.find(".edit-crop");
    var saveCropButton = parentContainer.find(".save-crop");

    croppieContainerElement.addClass("hidden");
    saveCropButton.addClass("hidden");
    editCropButton.addClass("hidden");
    imgPreviewElement.addClass("hidden");
    croppieElement.croppie('destroy');

    //if (parentContainer.data("crop-type")) {
    //    var imgCroppie = parentContainer.find(".file-uploader-croppie");
    //    var imageContainer = parentContainer.find(".file-uploader-img-container");
    //    //imgCroppie.croppie('bind', {
    //    //    url: ''
    //    //});
    //    imageContainer.addClass("hidden");
    //    parentContainer.find(".save-crop").addClass("hidden");
    //}
    //else {
    parentContainer.find(".file-link").remove();
    //}

    $(e.target).addClass("hidden");

    var fileUploaderButton = $(e.target).parent().find("a.fileinput-button");
    if (fileUploaderButton.length > 0) {
        fileUploaderButton.find("span").html(fileUploaderButton.find("span").html().replace("Replace", "Upload"));
    }

    return false;
}

function removeCustomFile(e) {
    var fileName = $(e.target).data("file-name")
    ShowConfirmationModal(`Are you sure you want to delete ${fileName}?`, function () {
        // if no files are left for this custom field file, add the hidden input so the server knows to delete the file
        var parentContainer = $(e.target).parents(".file-list");
        var customFieldID = $(e.target).data("custom-field-id");
        $(e.target).parent().remove();
        var customFieldFiles = parentContainer.find(".custom-field-file");

        if (customFieldFiles.length == 0) {
            parentContainer.append(`<input id="empty-custom-field-file" name="CustomFieldFile_${customFieldID}" class="hidden" type="file" />`);
            customFieldInput = parentContainer.find(`input[name="CustomFieldFile_${customFieldID}"]`);
        }
    });
}

function selectFile(e) {
    var uploader = $(e.target).parents(".file-uploader").find("input[type=file]");
    var extensions = $(e.target).data("extensions");
    var browseOptions = {
        title: 'Novi Files',
        multiple: false,
        extensions: extensions,
        oninsert: function (args) {
            var pathToFile = args.focusedFile.url;
            srcToFile(pathToFile, args.focusedFile.name).then(function (file) {
                uploader.fileupload('add', { files: [file] });
            });
        }
    };
    if (typeof (moxman) == "undefined") {
        $('<script>').attr('type', 'text/javascript').attr('src', moxieManagerScriptUrl).appendTo('body');
        var moxieIntervalID = setInterval(function () {
            if (typeof (moxman) != "undefined") {
                moxman.browse(browseOptions);
                clearInterval(moxieIntervalID);
            }
        }, 100);
    } else {
        moxman.browse(browseOptions);
    }
    return false;
}

function editCrop(e) {
    initCroppie($(e.target));
}

function saveCrop(e) {
    var uploaderContainer = $(e.target).parents(".file-uploader");
    var uploader = uploaderContainer.find("input[type=file]");
    var croppieContainerElement = uploaderContainer.find(".file-uploader-img-container");
    var croppieElement = uploaderContainer.find(".file-uploader-croppie");
    var imgPreviewElement = uploaderContainer.find("img");

    uploaderContainer.data("saving-crop", true);

    var fileName = croppieElement.data("file-name");

    //Our only two result formats are JPEG & PNG - If the file name isn't one of those, we have to adjust file name to be PNG
    var resultFormat = fileName.endsWith(".jpg") || fileName.endsWith(".jpeg") ? "jpeg" : "png";
    if (!fileName.endsWith(".png") && resultFormat == "png") {
        fileName = fileName.replace(/\.[^/.]+$/, "") + ".png";
    }

    var resultOptions = {
        type: 'blob',
        circle: false,
        size: 'original',
        format: resultFormat,
    };

    //JPEG has no transparency, so we have to fill the background with something
    if (resultFormat == "jpeg") {
        resultOptions.backgroundColor = '#FFFFFF';
    }

    croppieElement.croppie('result', resultOptions).then(function (blob) {
        uploader.fileupload('add', {
            files: [new File([blob], fileName, {
                type: blob.type,
            })]
        });
    });
}

function initCroppie(element) {
    var uploaderContainer = $(element).parents(".file-uploader");
    var croppieContainerElement = uploaderContainer.find(".file-uploader-img-container");
    var croppieElement = uploaderContainer.find(".file-uploader-croppie");
    var imgPreviewElement = uploaderContainer.find("img");

    var editCropButton = uploaderContainer.find(".edit-crop");
    var saveCropButton = uploaderContainer.find(".save-crop");

    var finalWidth = $(uploaderContainer).data("width");
    var finalHeight = $(uploaderContainer).data("height");
    var finalRatio = finalHeight / finalWidth;

    var width = Math.ceil(Math.min(uploaderContainer.width(), finalWidth));
    var height = Math.ceil(width * finalRatio);  

    editCropButton.addClass("hidden");
    imgPreviewElement.addClass("hidden");
    croppieContainerElement.removeClass("hidden");
    saveCropButton.removeClass("hidden");

    
    var cropType = $(uploaderContainer).data("crop-type").toLowerCase();
    

        var croppieInstance = $(croppieElement).croppie({
            viewport: {
                width: width - 2,
                height: height - 2,
                type: cropType
            },
            mouseWheelZoom: false,
            boundary: { width: width, height: height },
            enforceBoundary: false
        });
    $(uploaderContainer).find(".cr-slider").addClass("ignore");

    croppieElement.data("file-name", imgPreviewElement.attr("alt"));
    croppieElement.croppie('bind', { url: imgPreviewElement.attr("src") });
}

function FileAdded(fileName, parentContainer, fullPath, contentType, size, customFieldID = 0, isParentCustomer = false) {
    var lowerFileName = fileName.toLowerCase();
    var isImage = lowerFileName.indexOf(".jpg") > 0 || lowerFileName.indexOf(".jpeg") > 0 || lowerFileName.indexOf(".gif") > 0
        || lowerFileName.indexOf(".png") > 0 || lowerFileName.indexOf(".bmp") > 0 || lowerFileName.indexOf(".ico") > 0 || lowerFileName.indexOf(".svg") > 0;

    let showFileLink = true;

    // This is a override to make sure the link does not show. For example the signature. 
    if (parentContainer.data("hide-link")) {
        showFileLink = false
    }

    if (isImage) {
        var tempImgUrl = baseFileUrl + fullPath;
        var croppieElement = parentContainer.find(".file-uploader-croppie");
        //If we are using croppie in this file uploader, we need to run bind to correctly attach to the new image

        if (croppieElement.is(":visible") == true && parentContainer.data("saving-crop") != true) {
            croppieElement.data("file-name", fileName);

            croppieElement.croppie('bind', {
                url: tempImgUrl
            });            
        }
        else {
            var croppieContainerElement = parentContainer.find(".file-uploader-img-container");
            var imgPreviewElement = parentContainer.find("img");

            var editCropButton = parentContainer.find(".edit-crop");
            var saveCropButton = parentContainer.find(".save-crop");
            
            var imgPreview = parentContainer.find("img");
            var imgWidth = parentContainer.data("width");
            var imgHeight = parentContainer.data("height");
            
            imgPreviewElement.attr("alt", fileName).attr("src", tempImgUrl + "?" + "t=" + Math.floor(new Date() / 1000));

            if (parentContainer.data("saving-crop") == true) {

                croppieContainerElement.addClass("hidden");
                saveCropButton.addClass("hidden");
                editCropButton.removeClass("hidden");
                imgPreviewElement.removeClass("hidden");
                parentContainer.data("saving-crop", false);
                croppieElement.croppie('destroy');
            }
            else if (parentContainer.data("crop-type")) {
                initCroppie(editCropButton);
            }
            else {
                imgPreviewElement.removeClass("hidden");
            }

            // We need to check to make sure that the Croppie-Container and Preview-element are present.  If not, then we will show the file link.
            if (croppieContainerElement.length > 0 || imgPreviewElement.length > 0) {
                showFileLink = false;
            }
        }
    }

    if (showFileLink) {
        if (customFieldID > 0) {
            $("#empty-custom-field-file").remove();

            var customFieldFiles = parentContainer.find(".custom-field-file");
            var fileList = parentContainer.find(".file-list");
            var fileCount = customFieldFiles.length + 1;
            fileList.append(`<span class='custom-field-file' id='${fileCount}'>${fileName} <a href='' target='_blank'><i class='fa-solid fa-cloud-arrow-down'></i></a> <i class="fa-solid fa-trash-can remove-custom-file" data-file-name='${fileName}'></i></span>`);

            var newFileLink = parentContainer.find(`#${fileCount} a`);
            newFileLink.attr("href", customFieldFileBaseUrl + "/temp?fileName=" + fullPath.substring(fullPath.indexOf("/temp") + 6));

            var fileInfo = {
                fileName: fileName,
                contentType: contentType,
                fullPath: fullPath,
                size: size
            };

            var jsonString = JSON.stringify(fileInfo);

            var inputName = `${isParentCustomer ? "Parent" : ""}CustomFieldFile_${customFieldID}`;

            var customFieldInput = parentContainer.find(`input[name="${inputName}"]`);

            if (customFieldInput.length < fileCount) {
                newFileLink.parent().append(`<input name="${inputName}" value="${encodeURIComponent(jsonString)}" class="hidden" type="file"/>`);
                customFieldInput = parentContainer.find(`input[name="${inputName}"]`);
            } else {
                customFieldInput.attr("value", `${encodeURIComponent(jsonString)}`);
            }
        } else {
            var fileLink = parentContainer.find(".file-link");
            if (fileLink.length == 0) {
                parentContainer.prepend('<a class="file-link" target="_blank" />');
                fileLink = parentContainer.find(".file-link");
            }

            fileLink.attr("href", baseFileUrl + fullPath).html(fileName);
        }
    }

    parentContainer.find(".remove-file").removeClass("hidden");

    var hiddenFileNameInput = parentContainer.find("[type=hidden]:first");
    updateFileName(hiddenFileNameInput, fullPath);
    parentContainer.find(".progress").fadeOut(5000);

    const fileAddedEvent = new CustomEvent('fileAdded', { detail: { fileName: fileName } });
    parentContainer[0].dispatchEvent(fileAddedEvent);
}

function updateFileName(hiddenInput, newFileName) {
    hiddenInput.val(newFileName);
    if (hiddenInput[0]) {
        hiddenInput[0].dispatchEvent(new CustomEvent('updateFileName', { detail: newFileName }));
    }
}

function srcToFile(src, fileName) {
    return (fetch(src)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], fileName); })
    );
}

function ShowErrorMessage(fileUploader, error) {
    var parentContainer = fileUploader.parents(".file-uploader");
    var errorMessage = parentContainer.find(".error-message");

    if (errorMessage.length == 0) {
        parentContainer.append('<div class="alert alert-danger text-danger error-message" style="margin-top:5px;">  </div>');
        errorMessage = parentContainer.find(".error-message");
    }

    errorMessage.html("<span class='novicon novicon-warning' style='margin-right:5px;'></span> " + error);
}